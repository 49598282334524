.App {
  font-family: sans-serif;
  padding: 1em;
  background-color: #cd6529;
}

body {
  background-color: #f6f0e7;

}

.nav-bar {
  background-color: rgba(123, 60, 45, 0.5)
}

.slick-dots {
  bottom: -30px;
  /* Adjust the position as needed */
}

.slick-dots li {
  margin: 0 1px;
  /* Space between dots */
}

.slick-dots li button {
  background-color: transparent;
  /* Change background */
  border: 2px solid #fff;
  /* Change border */
  border-radius: 50%;
  /* Make dots round */
  width: 0.1px;
  /* Width of the dot */
  height: 0.1px;
  /* Height of the dot */
}

.ie-experience .slick-dots li.slick-active button {
  background-color: #fff;
  /* Active dot color */
  border-color: #000;
  /* Active border color */
}

.ie-experience .slick-dots li button {
  background-color: gray;
  /* Active dot color */
  border-color: #000;
  /* Active border color */
}

.eleveated-card_slider-container {
  @media only screen and (min-width: 1100px) {
    padding-left: 10rem;
    width: 40rem;
  }
  @media only screen and (min-width: 1300px) {
    padding-left: 0rem;
    
  }
  @media only screen and (min-width: 1600px) {
    margin-left: 4rem;
    
  }
  /* padding-top: 3rem; */
  align-content: end;
}

.eleveated-card_slider-arrow-left {
  
  
  @media only screen and (min-width: 1100px) {
    
    
  }
}

.elevated-card_container {
  @media only screen and (min-width: 1320px) {
    padding-top: 2rem;
  }
}

.library_intro-with-image-container {
  padding: 0;
  overflow: scroll;
}
.library_intro-with-image-container .bg-container {
  height: 10rem;
  @media only screen and (min-width: 600px) {
    height: 20rem;
  }
}